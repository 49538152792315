import { graphql } from 'gatsby';
import { array, shape, string } from 'prop-types';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/SEO';
import { groupJobsByOffice } from '../helpers';
import SectionConductor from '../sections/section-conductor';

const JobsPage = ({
  data: {
    contentfulLayout: { sections, title, metadata },
  },
  location,
}) => {
  const renderedSections = sections.map((section, i) => SectionConductor(section, i));

  return (
    <Layout>
      <SEO title={title} metadata={metadata} />
      {renderedSections}
    </Layout>
  );
};

export default JobsPage;

JobsPage.propTypes = {
  data: shape({
    contentfulLayout: shape({
      sections: array.isRequired,
    }),
  }),
};

export const pageQuery = graphql`
  query {
    contentfulLayout(contentfulid: { eq: "jobs" }) {
      title
      contentfulid
      url
      metadata {
        ...Metadata
      }
      ...AllSectionsFragment
    }
  }
`;
